import React from 'react'
import { Box } from '@chakra-ui/react'
import classnames from 'classnames'
import TableEntriesSelection from './TableEntriesSelection'
import { usePagination, DOTS } from '../../hooks'

const Pagination = ({
  onApply,
  totalCount,
  siblingCount = 1,
  className = 'pagination-bar',
  isFetching,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onApply({ currentPage: currentPage + 1 })
  }

  const onPrevious = () => {
    onApply({ currentPage: currentPage - 1 })
  }

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <Box as='div' display='flex' flexWrap='wrap' justifyContent='center' my='2'>
      <Box
        as='ul'
        my='2'
        display='flex'
        className={classnames(
          'pagination-container',
          {
            'pe-none': isFetching,
          },
          className,
        )}
        listStyleType='none'>
        <Box
          as='li'
          className={classnames('pagination-item', {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}>
          <Box as='div' className='arrow left' />
        </Box>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <Box as='li' className='pagination-item dots' key={index}>
                &#8230;
              </Box>
            )
          }

          return (
            <Box
              as='li'
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage,
              })}
              onClick={() => {
                if (pageNumber === currentPage) return
                onApply({ currentPage: pageNumber })
              }}
              key={index}>
              {pageNumber}
            </Box>
          )
        })}
        <Box
          as='li'
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}>
          <Box as='div' className='arrow right' />
        </Box>
      </Box>
      <TableEntriesSelection
        onApply={onApply}
        isDisabled={isFetching}
        pageSize={pageSize}
      />
    </Box>
  )
}

export default Pagination
