import React from 'react'
import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { formatNumber, numberWithCommas } from '../../utils'
import { CircleInfoIcon } from '../assetsComponents'
import { channelMapping, colorCodes } from '../../constants'

const renderCountTooltip = (number) => {
  return (
    <Tooltip
      label={numberWithCommas(number)}
      placement='right'
      fontWeight='600'
      className='s8'
      hasArrow
      p='8'
      bg='black'
      color='white'
      borderRadius='5'>
      <Box as='div' className='align-self-center' ml='2'>
        <CircleInfoIcon className='cursor-pointer align-self-center' />
      </Box>
    </Tooltip>
  )
}

const renderCountPopover = (number) => {
  return (
    <Popover placement='right'>
      <PopoverTrigger>
        <Box as='div' className='align-self-center' ml='2'>
          <CircleInfoIcon className='cursor-pointer align-self-center' />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        fontWeight='600'
        className='s8'
        hasArrow
        py='8'
        w='10rem'
        bg='black'
        color='white'
        borderRadius='5'>
        <PopoverArrow bg='black' />
        <PopoverBody>{numberWithCommas(number)}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const TrafficSummaryCardsSection = ({ reportData }) => {
  const getChannelLabel = (key) => {
    return channelMapping.find((channel) => channel.key === key)?.label
  }

  return (
    <React.Fragment>
      {Object.entries(reportData).map(([key, value]) => (
        <Box as='div' my='4' key={key}>
          <Text className='s4' mt='6' fontWeight='400'>
            {getChannelLabel(key)}
          </Text>
          <SimpleGrid
            spacing={4}
            templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
            {Object.entries({
              'total sent': value?.sent + value?.failed,
              ...value,
            }).map(([key, value]) => {
              return (
                <Card
                  key={key}
                  variant='outline'
                  cursor='pointer'
                  _hover={{
                    boxShadow:
                      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
                  }}>
                  <CardBody textAlign='center'>
                    <Stack mt='6' spacing='3'>
                      <Heading size='md' textTransform='capitalize'>
                        <Flex justify='center'>
                          <Box
                            as='div'
                            borderRadius='full'
                            h='8px'
                            w='18px'
                            className='align-self-center'
                            mr='2'
                            background={colorCodes[key]}
                          />
                          <Text
                            as='span'
                            color='blackAlpha.500'
                            fontWeight='500'>
                            {key}
                          </Text>
                          <Box
                            as='div'
                            className='align-self-center'
                            display={{ base: 'none', lg: 'block' }}>
                            {renderCountTooltip(value)}
                          </Box>
                          <Box
                            as='div'
                            className='align-self-center'
                            display={{ base: 'block', lg: 'none' }}>
                            {renderCountPopover(value)}
                          </Box>
                        </Flex>
                      </Heading>
                      <Text fontSize='4xl' fontWeight='600'>
                        {value?.toString().length > 4
                          ? formatNumber(value)
                          : numberWithCommas(value)}
                      </Text>
                    </Stack>
                  </CardBody>
                </Card>
              )
            })}
          </SimpleGrid>
        </Box>
      ))}
    </React.Fragment>
  )
}

export default TrafficSummaryCardsSection
