import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import RCXSpinner from '../spinner'

const ReportScreenMessages = ({
  isFetching,
  channel,
  reportLength,
  visibleColumnsLength,
}) => {
  return (
    <React.Fragment>
      {isFetching ? (
        <RCXSpinner height='100%' />
      ) : (
        <Flex justify='center' align='center' h='100%'>
          <Text as='div' className='s5' color='gray' mt='6'>
            {reportLength === 0 && channel
              ? 'No result found.'
              : visibleColumnsLength === 0
                ? 'Please select column to view the data. Export will be disabled if no column is selected.'
                : 'Select Channel to load the campaign report.'}
          </Text>
        </Flex>
      )}
    </React.Fragment>
  )
}

export default ReportScreenMessages
