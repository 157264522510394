import React from 'react'
import {
  Container,
  Text,
  Box,
  Stack,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LoginForm, LogoIcon } from '../components'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Sign In | ReplyCX'}</title>
      <meta name='title' content={'Sign In | ReplyCX'}></meta>
      <meta property='og:title' content={'Sign In | ReplyCX'}></meta>
      <meta property='twitter:title' content={'Sign In | ReplyCX'}></meta>
    </Helmet>
  )
}

const Login = () => {
  return (
    <React.Fragment>
      {renderHelmet()}
      <main>
        <Container as='section' w='100vw' h='100vh' display='grid'>
          <Box as='div' m='auto' w='100%'>
            <Box
              my={{ base: '2rem', sm: '2rem', md: '2rem', lg: '0rem' }}
              boxShadow={{
                base: 'none',
                sm: 'none',
                md: 'dark-lg',
                lg: 'dark-lg',
              }}
              p={{ base: '0', sm: '0', md: '4', lg: '6' }}
              rounded='md'
              bg='white'>
              <Stack>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <ChakraLink as={ReactRouterLink} to='/'>
                    <LogoIcon
                      width='168'
                      height='168'
                      fill='rgb(24.708557%, 53.329468%, 77.249146%)'
                    />
                  </ChakraLink>
                </Box>
                <Text fontSize='1.5rem' fontWeight='700' textAlign='center'>
                  Welcome back
                </Text>
                <LoginForm />
              </Stack>
            </Box>
          </Box>
        </Container>
      </main>
    </React.Fragment>
  )
}

export default Login
