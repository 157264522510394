import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useToast } from '@chakra-ui/react'
import { useUserService } from '../hooks'
import { RCXSpinner } from '../components'
import { VARIABLES, getCookieByName, removeCookie } from '../utils'
import { defaultErrorMessage } from '../utils/constants'
import { logout as userLogout } from '../rest-client/service'

const Logout = () => {
  const { setUser } = useUserService()
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    const logout = async () => {
      const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
      try {
        await userLogout({ refreshToken })
        setUser(null)
        removeCookie()
        navigate('/', { replace: true })
      } catch (error) {
        const { response } = error
        toast({
          description: response?.data?.message || defaultErrorMessage,
          status: 'error',
        })
      }
    }
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box as='div'>
      <RCXSpinner h='100vh' />
    </Box>
  )
}

export default Logout
