import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { ReactApexchart } from '../common'
import {
  allChannelColorArray,
  channelMappingObject,
  emailChannelColorArray,
  smsChannelColorArray,
  whatsappChannelColorArray,
} from '../../constants'
import { summaryViewTypeObject } from '../../pages'

const SummaryChartAndGraphSection = ({ reportData, viewAs }) => {
  // for total
  const totalSmsSent =
    (reportData?.[channelMappingObject.sms]?.sent || 0) +
    (reportData?.[channelMappingObject.sms]?.failed || 0)
  const totalWhatsappSent =
    (reportData?.[channelMappingObject.whatsapp]?.sent || 0) +
    (reportData?.[channelMappingObject.whatsapp]?.failed || 0)
  const totalEmailSent =
    (reportData?.[channelMappingObject.email]?.sent || 0) +
    (reportData?.[channelMappingObject.email]?.failed || 0)

  // for individual delivered
  const smsDelivered = reportData?.[channelMappingObject.sms]?.delivered || 0
  const whatsappDelivered =
    reportData?.[channelMappingObject.whatsapp]?.delivered || 0
  const emailDelivered =
    reportData?.[channelMappingObject.email]?.delivered || 0

  // for individual seen
  const smsSeen = reportData?.[channelMappingObject.sms]?.seen || 0
  const whatsappSeen = reportData?.[channelMappingObject.whatsapp]?.seen || 0
  const emailSeen = reportData?.[channelMappingObject.email]?.seen || 0

  // for individual clicked
  const smsClicked = reportData?.[channelMappingObject.sms]?.clicked || 0
  const whatsappClicked =
    reportData?.[channelMappingObject.whatsapp]?.clicked || 0
  const emailClicked = reportData?.[channelMappingObject.email]?.clicked || 0

  const graphData = [
    {
      series:
        viewAs === summaryViewTypeObject.BAR_GRAPH
          ? [
              {
                name: 'Total Sent',
                data: [totalSmsSent, totalWhatsappSent, totalEmailSent],
              },
              {
                name: 'Delivered',
                data: [smsDelivered, whatsappDelivered, emailDelivered],
              },
              {
                name: 'Seen',
                data: [smsSeen, whatsappSeen, emailSeen],
              },
              {
                name: 'Clicked',
                data: [smsClicked, whatsappClicked, emailClicked],
              },
            ]
          : [
              totalSmsSent + totalWhatsappSent + totalEmailSent,
              smsDelivered + whatsappDelivered + emailDelivered,
              smsSeen + whatsappSeen + emailSeen,
              smsClicked + whatsappClicked + emailClicked,
            ],
      colors: allChannelColorArray,
      titleText: 'SMS, Whatsapp and Email',
      categories: ['SMS', 'Whatsapp', 'Email'],
      labels: ['Total Sent', 'Delivered', 'Seen', 'Clicked'],
    },
    reportData?.[channelMappingObject.sms] && {
      series:
        viewAs === summaryViewTypeObject.BAR_GRAPH
          ? [{ name: 'SMS', data: [totalSmsSent, smsDelivered] }]
          : [totalSmsSent, smsDelivered],
      colors: smsChannelColorArray,
      titleText: 'SMS',
      categories: ['Total Sent', 'Delivered'],
      labels: ['Total Sent', 'Delivered'],
    },
    reportData?.[channelMappingObject.whatsapp] && {
      series:
        viewAs === summaryViewTypeObject.BAR_GRAPH
          ? [
              {
                name: 'Whatsapp',
                data: [totalWhatsappSent, whatsappDelivered],
              },
            ]
          : [totalWhatsappSent, whatsappDelivered],
      colors: whatsappChannelColorArray,
      titleText: 'Whatsapp',
      categories: ['Total Sent', 'Delivered'],
      labels: ['Total Sent', 'Delivered'],
    },
    reportData?.[channelMappingObject.email] && {
      series:
        viewAs === summaryViewTypeObject.BAR_GRAPH
          ? [
              {
                name: 'Email',
                data: [totalEmailSent, emailSeen, emailClicked],
              },
            ]
          : [totalEmailSent, emailSeen, emailClicked],
      colors: emailChannelColorArray,
      titleText: 'Email',
      categories: ['Total Sent', 'Seen', 'Clicked'],
      labels: ['Total Sent', 'Seen', 'Clicked'],
    },
  ]

  return (
    <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} gap={4}>
      {graphData.map((graph, index) => (
        <React.Fragment key={index}>
          {graph ? (
            <ReactApexchart
              type={viewAs === summaryViewTypeObject.BAR_GRAPH ? 'bar' : 'pie'}
              series={graph.series}
              options={
                viewAs === summaryViewTypeObject.BAR_GRAPH
                  ? {
                      colors: graph.colors,
                      chart: {
                        type: 'bar',
                        stacked: index !== 0 ? false : true,
                        toolbar: {
                          show: true,
                          tools: {
                            download: false,
                          },
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            legend: {
                              position: 'bottom',
                              offsetX: -10,
                              offsetY: 0,
                            },
                          },
                        },
                      ],
                      plotOptions: {
                        bar: {
                          columnWidth: '48px',
                          // borderRadius: 10,
                          // borderRadiusApplication: 'around', // "around" / "end"
                          // borderRadiusWhenStacked: 'last', // "all"/"last"
                          distributed: index !== 0 ? true : false,
                          dataLabels: {
                            total: {
                              enabled: true,
                            },
                            style: {
                              fontSize: '14px',
                            },
                          },
                        },
                      },
                      stroke: {
                        width: 1,
                        colors: ['#fff'],
                      },
                      title: {
                        text: graph.titleText,
                      },
                      xaxis: {
                        categories: graph.categories,
                      },
                      fill: {
                        opacity: 1,
                      },
                      // top label
                      legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40,
                      },
                    }
                  : {
                      colors: graph.colors,
                      labels: graph?.labels,
                      stroke: {
                        width: 1,
                        colors: ['#fff'],
                      },
                      title: {
                        text: graph?.titleText,
                      },
                    }
              }
            />
          ) : null}
        </React.Fragment>
      ))}
    </SimpleGrid>
  )
}

export default SummaryChartAndGraphSection
