import React, { useEffect, useState } from 'react'
import { Box, Menu, MenuList } from '@chakra-ui/react'
import MenuButton from './MenuButton'
import MenuSearchInput from './MenuSearchInput'
import { Checkbox } from '../form'
import { defaultSelectedColumns } from '../../pages'

const SelectionMenu = ({
  buttonText,
  buttonLeftIcon,
  selectedColumns,
  setSelectedColumns,
}) => {
  const [search, setSearch] = useState('')
  const [columns, setColumns] = useState({
    default: defaultSelectedColumns,
    filtered: defaultSelectedColumns,
  })

  useEffect(() => {
    const results = columns.default.filter((column) =>
      column.label?.toLowerCase()?.includes(search?.toLowerCase()?.trim()),
    )
    setColumns((prev) => ({ ...prev, filtered: results || [] }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <Box as='div' my='2'>
      <Menu>
        <MenuButton
          buttonText={buttonText}
          buttonLeftIcon={buttonLeftIcon}
          textAlign='left'
        />
        <MenuList p='2'>
          <MenuSearchInput
            search={search}
            setSearch={setSearch}
            placeholder='Search'
          />
          <Box as='div' display='flex' flexDirection='column'>
            <React.Fragment>
              {columns?.filtered?.length > 0 ? (
                <React.Fragment>
                  {columns?.filtered?.map((column, index) => (
                    <Checkbox
                      value={column.key}
                      isChecked={selectedColumns.includes(column.key)}
                      onChange={(event) => {
                        const checkedColumn = event.target.value
                        if (event.target.checked) {
                          setSelectedColumns([
                            ...selectedColumns,
                            checkedColumn,
                          ])
                        } else {
                          setSelectedColumns(
                            selectedColumns.filter(
                              (column) => column !== checkedColumn,
                            ),
                          )
                        }
                      }}
                      label={column.label}
                      key={index}
                      my='2'
                    />
                  ))}
                </React.Fragment>
              ) : (
                <Box as='span' textAlign='center'>
                  Not Found.
                </Box>
              )}
            </React.Fragment>
          </Box>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default SelectionMenu
