import React from 'react'
import { Select } from '@chakra-ui/react'

const SelectionField = ({
  options = [],
  placeholder,
  isDisabled = false,
  ...rest
}) => {
  return (
    <Select
      placeholder={placeholder}
      _focusVisible={{
        boxShow: 'none',
      }}
      isDisabled={isDisabled}
      {...rest}>
      <SelectionOptions options={options} {...rest} />
    </Select>
  )
}

export const SelectionOptions = ({
  options,
  isKeyValuePair = false,
  keyprop = 'key',
  valueprop = 'value',
  labelProp = 'key',
  customKeyprop,
  customValueprop,
  customLabelProp,
  getCustomKeyValuePropsAsOption = false,
  getKeyAsOption = false,
  getValueAsOption = false,
}) => {
  return (
    <React.Fragment>
      {options.map((option, index) => (
        <option
          key={
            isKeyValuePair
              ? `${option[keyprop]}`
              : getKeyAsOption
                ? option.key
                : getCustomKeyValuePropsAsOption
                  ? option[customKeyprop]
                  : getValueAsOption
                    ? option.value
                    : `${option}-${index}`
          }
          value={
            isKeyValuePair
              ? [
                  JSON.stringify({
                    key: option[keyprop],
                    value: option[valueprop],
                  }),
                ]
              : getKeyAsOption
                ? option.key
                : getCustomKeyValuePropsAsOption
                  ? option[customValueprop]
                  : getValueAsOption
                    ? option.value
                    : option
          }>
          {isKeyValuePair
            ? option[labelProp]
            : getKeyAsOption
              ? option.value
              : getCustomKeyValuePropsAsOption
                ? option[customLabelProp]
                : getValueAsOption
                  ? option.label
                  : option}
        </option>
      ))}
    </React.Fragment>
  )
}

export default SelectionField
