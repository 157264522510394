import React from 'react'
import { Box, Flex, Link as ChakraLink, Text, Divider } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import SidebarMenu from './SidebarMenu'
import NavItems from './NavItems'
import { LogoIcon } from '../assetsComponents'
import TimezoneSelection from './TimezoneSelection'
import { useUserService } from '../../hooks'

const Sidebar = ({ isActive = true, onToggle }) => {
  const { user } = useUserService()
  const links = [
    {
      name: 'Traffic Summary',
      path: `${user?.accountId}/traffic-summary`,
    },
    {
      name: 'Advance Report',
      path: `${user?.accountId}/advance-report`,
    },
  ]
  return (
    <Box
      background='black'
      color='white'
      h='100vh'
      w='100%'
      opacity={isActive ? '1' : '0.9'}
      className='transition-opacity sidebar'
      py='4'
      display='flex'
      flexDirection='column'>
      <Box
        as='div'
        display='flex'
        justifyContent='space-between'
        flexDirection='column'
        overflowY='overlay'
        overflowX='hidden'
        flexGrow='1'>
        <Box as='div'>
          <ChakraLink
            as={ReactRouterLink}
            to={`${user?.accountId}/traffic-summary`}
            textDecoration='none'
            _hover={{ textDecoration: 'none' }}
            className='z100'>
            <Flex className='nav-element' zIndex='100' mx='4' p='2'>
              <LogoIcon
                height='38'
                width='38'
                fill='white'
                className='align-self-center'
              />
              <Text as='h3' ml='4' className='s4 align-self-center'>
                ReplyCX
              </Text>
            </Flex>
          </ChakraLink>
          <Divider borderColor='gray' opacity='1' my='2' />
          <NavItems links={links} onToggle={onToggle} />
        </Box>
      </Box>
      <Box as='div'>
        <TimezoneSelection />
        <SidebarMenu />
      </Box>
    </Box>
  )
}

export default Sidebar
