import React from 'react'

const LogoIcon = ({ height, width, fill, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      zoomAndPan='magnify'
      viewBox='0 0 375 374.999991'
      height={height}
      preserveAspectRatio='xMidYMid meet'
      version='1.0'
      cursor='pointer'
      {...rest}>
      <defs>
        <clipPath id='id1'>
          <path
            d='M 6.816406 5.964844 L 369.066406 5.964844 L 369.066406 368.214844 L 6.816406 368.214844 Z M 6.816406 5.964844 '
            clipRule='nonzero'
          />
        </clipPath>
      </defs>
      <g clipPath='url(#id1)'>
        <path
          fill={fill}
          d='M 187.914062 5.996094 C 287.949219 5.996094 369.027344 87.09375 369.027344 187.121094 C 369.027344 287.144531 287.949219 368.246094 187.914062 368.246094 C 87.886719 368.246094 6.789062 287.144531 6.789062 187.121094 C 6.789062 87.09375 87.886719 5.996094 187.914062 5.996094 Z M 165.273438 255.042969 L 165.273438 209.761719 C 165.273438 209.761719 255.824219 187.121094 301.105469 255.042969 C 301.105469 180.023438 240.304688 119.199219 165.273438 119.199219 L 165.273438 73.917969 L 74.710938 164.480469 Z M 165.273438 255.042969 '
          fillOpacity='1'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

export default LogoIcon
