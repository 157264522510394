import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { RCXSpinner } from './components'
import CacheBuster from './CacheBuster'

const App = () => {
  return (
    <Suspense fallback={<RCXSpinner h='100vh' />}>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload()
          }
          return null
        }}
      </CacheBuster>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Suspense>
  )
}

export default App
