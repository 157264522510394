import { Box, Container, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { BackToHomeBtn } from '../components'

const ErrorView = () => {
  return (
    <main>
      <Container
        as='section'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        w='100vw'
        h='100vh'>
        <Stack>
          <Text
            fontSize={{ base: '1rem', sm: '1rem', md: '1rem', lg: '1.25rem' }}
            fontWeight='400'
            textAlign='center'>
            Something went wrong! Please try again later.
          </Text>
          <Box as='div' textAlign='center'>
            <BackToHomeBtn />
          </Box>
        </Stack>
      </Container>
    </main>
  )
}

export default ErrorView
