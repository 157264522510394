import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Flex,
  useDisclosure,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react'
import { SidebarWrapper } from '../sidebar'
import RCXSpinner from '../spinner'
import { HamburgerIcon, LogoIcon } from '../assetsComponents'
import { useResolutionService, useUserService } from '../../hooks'
import { TimezoneContext } from '../../contexts'

const Layout = ({ children }) => {
  const [isFetchingTimezone, setIsFetchingTimezone] = useState(true)
  const [timezone, setTimezone] = useState('')
  const { isLGScreen } = useResolutionService()
  const { user } = useUserService()
  const { onToggle, isOpen, ...rest } = useDisclosure({
    defaultIsOpen: isLGScreen ? true : false,
  })
  return (
    <TimezoneContext.Provider
      value={{
        isFetchingTimezone,
        setIsFetchingTimezone,
        timezone,
        setTimezone,
      }}>
      <Flex
        overflow='hidden'
        h='100vh'
        p='0'
        flexDirection={{
          base: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
        }}>
        {isOpen && (
          <Box
            as='div'
            className='fade backdrop'
            display={{ base: 'block', lg: 'none' }}
          />
        )}
        <SidebarWrapper {...rest} isOpen={isOpen} onToggle={onToggle} />
        <Box
          as='div'
          display={{ base: 'flex', sm: 'flex', md: 'flex', lg: 'none' }}
          className='top-menu fixed-top top-0'
          px='4'
          py='2'
          justifyContent='space-between'>
          <ChakraLink
            as={ReactRouterLink}
            to={`${user?.accountId}/traffic-summary`}
            textDecoration='none'
            _hover={{ textDecoration: 'none' }}>
            <Flex>
              <LogoIcon width='28' height='28' fill='RGBA(0, 0, 0, 0.80)' />
              <Text
                as='h5'
                ml='1'
                className='s6 align-self-center'
                textDecoration='none'>
                ReplyCX
              </Text>
            </Flex>
          </ChakraLink>
          <HamburgerIcon onClick={onToggle} className='align-self-center' />
        </Box>
        {isFetchingTimezone ? (
          <RCXSpinner h='100vh' flexGrow='1' />
        ) : (
          <Box as='div' flexGrow='1' px='3' overflow='auto'>
            {children}
          </Box>
        )}
      </Flex>
    </TimezoneContext.Provider>
  )
}

export default Layout
