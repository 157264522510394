const cookiePrefix = process.env.REACT_APP_COOKIE_PREFIX

export const VARIABLES = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  REFRESH_TOKEN: `${cookiePrefix}_refresh_token`,
  ACCESS_TOKEN: `${cookiePrefix}_access_token`,
  USERID: `${cookiePrefix}_uId`,
  EMAIL: `${cookiePrefix}_email`,
  ACCOUNTID: `${cookiePrefix}_accountId`,
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG,
  BUGSNAG_RELEASE_STAGES: process.env.REACT_APP_BUGSNAG_RELEASE_STAGES,
}
