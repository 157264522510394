import React from 'react'
import { Button, MenuButton as ChakraMenuButton } from '@chakra-ui/react'

const MenuButton = ({ buttonText, buttonLeftIcon, ...rest }) => {
  return (
    <ChakraMenuButton
      as={Button}
      leftIcon={buttonLeftIcon}
      variant='outline'
      className='menu-btn'
      borderColor='gray.200'
      borderRadius='0.375rem'
      fontWeight='400'
      width='100%'
      {...rest}>
      {buttonText}
    </ChakraMenuButton>
  )
}

export default MenuButton
