import React, { useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react'
import { SignoutIcon, UserAvatarIcon } from '../assetsComponents'
import { VARIABLES, getCookieByName, removeCookie } from '../../utils'
import { useUserService } from '../../hooks'
import { logout as userLogout } from '../../rest-client/service'
import { defaultErrorMessage } from '../../utils/constants'

const SidebarMenu = () => {
  const { user, setUser } = useUserService()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  const logout = async () => {
    const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
    setIsLoading(true)
    userLogout({ refreshToken })
      .then((data) => {
        setIsLoading(false)
        setUser(null)
        removeCookie()
        navigate('/', { replace: true })
      })
      .catch((error) => {
        const { response } = error
        setIsLoading(false)
        toast({
          description: response?.data?.message || defaultErrorMessage,
          status: 'error',
        })
      })
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <Box mx='4'>
          <MenuButton
            as={Button}
            transition='all 0.2s'
            variant='none'
            width='100%'
            px='0'
            h='48px'
            isDisabled={isLoading}
            className={classNames('nav-element', {
              active: isOpen,
            })}>
            <Flex p='2' mr='4'>
              <Avatar
                background='white'
                h='2rem'
                w='2rem'
                icon={<UserAvatarIcon />}
              />
              <Text
                as='h5'
                ml='4'
                textAlign='left'
                className='s7 custom-ellipsis-text align-self-center'>
                {isLoading ? 'Logging Off...' : user?.email}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList className='bg-gray-800 border-gray-900' w='228px'>
            <MenuItem className='bg-gray-800 nav-menu-item' onClick={logout}>
              <SignoutIcon className='icon-md align-self-center' />
              <Text
                ml='2'
                alignSelf='center'
                textAlign='left'
                className='s7'
                fontSize='0.813rem'>
                Log out
              </Text>
            </MenuItem>
          </MenuList>
        </Box>
      )}
    </Menu>
  )
}

export default SidebarMenu
