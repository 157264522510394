import React from 'react'
import { FormControl } from '@chakra-ui/react'
import { SelectionField, FormHelperText } from '../form'

const CampaignSelection = ({
  selectedCampaign,
  campaigns,
  isFetching,
  setSelectedCampaign,
}) => {
  return (
    <FormControl my='2'>
      <SelectionField
        onChange={(e) => {
          const campaignValue = e.target.value
          setSelectedCampaign(campaignValue)
        }}
        value={selectedCampaign}
        options={campaigns?.list}
        getValueAsOption
        isDisabled={isFetching || campaigns?.isLoading}
      />
      <FormHelperText
        text={campaigns?.isLoading ? 'Fetching Campaigns..' : 'Select Campaign'}
      />
    </FormControl>
  )
}

export default CampaignSelection
