import React from 'react'
import { FormControl } from '@chakra-ui/react'
import { InputField, FormHelperText } from '../form'
import { allCampaignsOption } from '../../constants'

const ReportDatePicker = ({
  sent,
  setSent,
  sentTill,
  isFetching,
  setCampaigns,
  setSelectedCampaign,
  sentKey,
  helperText,
  isRequired,
}) => {
  return (
    <FormControl my='2'>
      <InputField
        onChange={(e) => {
          setSent(e.target.value)
        }}
        onBlur={(e) => {
          const sentFrom = e.target.value
          if (sentTill && sentFrom) {
            setCampaigns({ isLoading: true, list: [allCampaignsOption] })
            setSelectedCampaign(allCampaignsOption.value)
          }
        }}
        value={sent}
        size='md'
        type='datetime-local'
        isDisabled={isFetching}
        _focusVisible={{
          boxShow: 'none',
        }}
      />
      <FormHelperText
        text={helperText}
        isRequired={isRequired}
        requiredText={'(required)'}
      />
    </FormControl>
  )
}

export default ReportDatePicker
