import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
// route
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
// pages
import {
  ErrorView,
  TrafficSummaryReport,
  Login,
  NotFound,
  AdvanceReport,
  GoogleSingInCallback,
  GoogleSingInError,
  Logout,
} from '../pages'
// contexts
import { AuthContext } from '../contexts'
// utils
import { VARIABLES, getCookieByName } from '../utils'
import ErrorBoundary from '../utils/BugsnagClient'

const getUserData = () => {
  if (document.cookie) {
    const token = getCookieByName(VARIABLES.ACCESS_TOKEN)
    const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
    const email = getCookieByName(VARIABLES.EMAIL)
    const accountId = getCookieByName(VARIABLES.ACCOUNTID)
    return { accessToken: token, refreshToken, email, accountId }
  } else {
    return null
  }
}

const MainRoutes = () => {
  const userObj = getUserData()
  const [user, setUser] = useState(userObj || null)

  return (
    <ErrorBoundary FallbackComponent={() => <ErrorView />}>
      <AuthContext.Provider value={{ user, setUser }}>
        <Routes>
          {/* Public Route */}
          <Route element={<PublicRoute />}>
            <Route index path='/' element={<Login />} />
            <Route path='/google-callback' element={<GoogleSingInCallback />} />
            <Route path='/google-login-error' element={<GoogleSingInError />} />
          </Route>
          {/* Private Route */}
          <Route element={<PrivateRoute />}>
            <Route
              path={`${user?.accountId}/traffic-summary`}
              element={<TrafficSummaryReport />}
            />
            <Route
              path={`${user?.accountId}/advance-report`}
              element={<AdvanceReport />}
            />
            <Route path='/logout' element={<Logout />} />
          </Route>
          {/* Not Found Page */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AuthContext.Provider>
    </ErrorBoundary>
  )
}

export default MainRoutes
