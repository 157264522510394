import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Box,
  Button,
  Flex,
  FormControl,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { FormHelperText, InputField, SelectionField } from '../form'
import { CampaignSelection, ReportDatePicker } from '../common'
import { SelectionMenu } from '../selectionMenu'
import { ColumnsIcon } from '../assetsComponents'
import {
  allCampaignsOption,
  channelMapping,
  channelMappingObject,
  channelStatusMapping,
} from '../../constants'
import { ARFilterInitialValue, defaultSelectedColumns } from '../../pages'
import { VARIABLES, getCookieByName } from '../../utils'
import { getReportCampaigns } from '../../rest-client/service'
import { defaultErrorMessage } from '../../utils/constants'

const AdvanceReportFilters = ({
  isFetching,
  onApply,
  columnSelectionChangeHandler,
}) => {
  const [sentFrom, setSentFrom] = useState('')
  const [sentTill, setSentTill] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState(
    allCampaignsOption.value,
  )
  const [channel, setChannel] = useState('')
  const [channelStatus, setChannelStatus] = useState('')
  const [message, setMessage] = useState('')
  const [senderId, setSenderId] = useState('')
  const [destinationNo, setDestinationNo] = useState('')
  const [campaigns, setCampaigns] = useState({
    isLoading: true,
    list: [allCampaignsOption],
    allCampaignsArray: [],
  })
  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns.map((column) => column.key),
  )
  const accountId = getCookieByName(VARIABLES.ACCOUNTID)
  const toast = useToast()

  useEffect(() => {
    const getCampaigns = async () => {
      getReportCampaigns({
        ...(sentFrom && sentTill && { sentFrom: sentFrom }),
        ...(sentFrom && sentTill && { sentTill: sentTill }),
        accountId,
      })
        .then((data) => {
          const { data: response } = data
          const campaignsList = response.map((campaign) => ({
            label: campaign?.bot_name,
            value: campaign?.id,
          }))
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption, ...campaignsList],
            allCampaignsArray: campaignsList.map((campaign) => campaign.value),
          })
        })
        .catch((error) => {
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption],
          })
        })
    }
    if (campaigns.isLoading) getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns.isLoading])

  const resetFilter = () => {
    setSentFrom('')
    setSentTill('')
    setSelectedCampaign(allCampaignsOption.value)
    setChannel('')
    setChannelStatus('')
    setMessage('')
    setSenderId('')
    setDestinationNo('')
    setCampaigns({
      isLoading: true,
      list: [allCampaignsOption],
      allCampaignsArray: [],
    })
    onApply(ARFilterInitialValue)
    setSelectedColumns(defaultSelectedColumns.map((column) => column.key))
  }

  useEffect(() => {
    columnSelectionChangeHandler(selectedColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns])

  return (
    <Box as='section'>
      <SimpleGrid minChildWidth='230px' spacingX='40px' spacing='5px'>
        <ReportDatePicker
          sent={sentFrom}
          setSent={setSentFrom}
          sentTill={sentTill}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentFrom'}
          helperText='Sent From'
          isRequired={!sentFrom && sentTill}
        />
        <ReportDatePicker
          sent={sentTill}
          setSent={setSentTill}
          sentTill={sentFrom}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentTill'}
          helperText='Sent Till'
          isRequired={sentFrom && !sentTill}
        />
        <CampaignSelection
          selectedCampaign={selectedCampaign}
          campaigns={campaigns}
          isFetching={isFetching}
          setSelectedCampaign={setSelectedCampaign}
        />
        <FormControl my='2'>
          <SelectionField
            placeholder={!channel ? 'Select Channel' : ''}
            value={channel}
            onChange={(e) => {
              const channelValue = e.target.value
              setChannel(channelValue)
              setChannelStatus('')
              // We are hiding message search input while email channel is selected
              // In elastic search when there are more than 256 character search doesn't work so this decision has been made.
              if (channelValue === channelMappingObject.email) {
                setMessage('')
              }
            }}
            options={channelMapping.map((channel) => ({
              value: channel?.key,
              label: channel?.label,
            }))}
            getValueAsOption
            isDisabled={isFetching}
          />
          <FormHelperText text='Select Channel' />
        </FormControl>
        <FormControl my='2'>
          <SelectionField
            placeholder={!channelStatus && !channel ? 'Select EDR Status' : ''}
            value={channelStatus}
            onChange={(e) => {
              setChannelStatus(e.target.value)
            }}
            title={
              !channel ? 'Please select channel first to choose EDR status' : ''
            }
            options={channelStatusMapping[channel]}
            getValueAsOption
            isDisabled={isFetching || !channel}
          />
          <FormHelperText text='Select EDR Status' />
        </FormControl>
        <SelectionMenu
          buttonText='Columns'
          buttonLeftIcon={<ColumnsIcon />}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
        <InputField
          placeholder='Message'
          type='text'
          my='2'
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName={classNames('mt-0', {
            // We are hiding message search input while email channel is selected
            // In elastic search when there are more than 256 character search doesn't work so this decision has been made.
            'd-none': channel === channelMappingObject.email,
          })}
        />
        <InputField
          placeholder='Sender ID'
          my='2'
          type='text'
          value={senderId}
          onChange={(e) => {
            setSenderId(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName='mt-0'
        />
        <InputField
          placeholder='Destination No'
          my='2'
          type='text'
          value={destinationNo}
          onChange={(e) => {
            setDestinationNo(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName='mt-0'
        />
      </SimpleGrid>
      <Flex justify='end'>
        <Button
          m='2'
          variant='outline'
          title={
            !channel
              ? 'Please select "Channel"'
              : (sentFrom || sentTill) && !(sentFrom && sentTill)
                ? 'Please select "Sent From" and "Sent Till"'
                : ''
          }
          isDisabled={
            isFetching ||
            ((sentFrom || sentTill) && !(sentFrom && sentTill)) ||
            !channel
          }
          colorScheme='green'
          onClick={() =>
            onApply({
              sentFrom,
              sentTill,
              campaigns:
                selectedCampaign === allCampaignsOption.value
                  ? selectedCampaign
                  : [selectedCampaign],
              channel,
              channelStatus,
              message,
              senderId,
              destinationNo,
              allCampaignsArray: campaigns.allCampaignsArray,
              currentPage: 1,
            })
          }>
          Apply
        </Button>
        <Button
          m='2'
          variant='outline'
          // 1) !(sentTill || sentFrom || channel || channelStatus || message || senderId || destinationNo || selectedCampaign !== allCampaignsOption.value || selectedColumns?.length < 9)
          // sentTill is not selected, sentFrom is not selected, channel is not selected, channelStatus is not selected, message is not selected, senderId is not selected, destinationNo is not selected, campaigns has all campaigns selected, and from columns all columns are checked, the reset button will be disabled
          // 2) isFetching
          // isFetching is true disabled reset button
          isDisabled={
            !(
              sentTill ||
              sentFrom ||
              channel ||
              channelStatus ||
              message ||
              senderId ||
              destinationNo ||
              selectedCampaign !== allCampaignsOption.value ||
              selectedColumns?.length < 9
            ) || isFetching
          }
          onClick={resetFilter}>
          Reset
        </Button>
      </Flex>
    </Box>
  )
}

export default AdvanceReportFilters
