import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { InputField } from '../form'

const EmailField = ({ setEmail, ...rest }) => {
  const { state } = useLocation()

  useEffect(() => {
    if (state?.email) {
      setEmail(state?.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return <InputField type='text' name='email' label='Email' {...rest} /> // type='email'
}

export default EmailField
