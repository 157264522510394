export const reportFormatOptions = [
  {
    value: 'csv',
    label: 'CSV',
  },
  {
    value: 'xlsx',
    label: 'XLS',
  },
]

export const pageSizeArray = [25, 50, 75, 100]
