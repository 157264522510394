import React from 'react'

export const AuthContext = React.createContext({
  auth: false,
  setAuth: () => {},
  user: null,
  setUser: () => {},
})

export const TimezoneContext = React.createContext({
  isFetchingTimezone: true,
  setIsFetchingTimezone: () => {},
  timezone: '',
  setTimezone: () => {},
})
