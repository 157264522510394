import React from 'react'
import { Box, Container, Stack, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { BackToHomeBtn } from '../components'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'404: Not found | ReplyCX'}</title>
      <meta name='title' content={'404: Not found | ReplyCX'}></meta>
      <meta property='og:title' content={'404: Not found | ReplyCX'}></meta>
      <meta
        property='twitter:title'
        content={'404: Not found | ReplyCX'}></meta>
    </Helmet>
  )
}

const NotFound = () => {
  return (
    <React.Fragment>
      {renderHelmet()}
      <main>
        <Container
          as='section'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          w='100vw'
          h='100vh'>
          <Stack>
            <Text
              as='h2'
              fontSize={{
                base: '1.5rem',
                sm: '1.5rem',
                md: '2rem',
                lg: '2.5rem',
              }}
              fontWeight='600'
              textAlign='center'>
              404 | Page Not Found
            </Text>
            <Text
              fontSize={{ base: '1rem', sm: '1rem', md: '1rem', lg: '1.25rem' }}
              fontWeight='400'
              textAlign='center'>
              You just hit a route that doesn't exist.
            </Text>
            <Box as='div' textAlign='center'>
              <BackToHomeBtn />
            </Box>
          </Stack>
        </Container>
      </main>
    </React.Fragment>
  )
}

export default NotFound
