import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useUserService } from '../hooks'
import { Layout } from '../components'

const PrivateRoute = () => {
  const { user } = useUserService()
  const location = useLocation()
  if (!user) {
    return (
      <Navigate
        to={{
          pathname: `/${location?.search ? location?.search : ''}`,
          state: {
            from: `${location.pathname}${location.search}`,
          },
        }}
      />
    )
  }
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default PrivateRoute
