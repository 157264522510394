import { cookieExpires } from './constants'
import { getCurrentExpiresDate } from './utility'
import { VARIABLES } from './variables'

const currentExpires = getCurrentExpiresDate()

export const saveTokenCookie = (accessToken, refreshToken) => {
  document.cookie = `${VARIABLES.ACCESS_TOKEN}=${accessToken};expires=${cookieExpires};path=/`
  document.cookie = `${VARIABLES.REFRESH_TOKEN}=${refreshToken};expires=${cookieExpires};path=/`
  return
}

export const getCookieByName = (cookieName) => {
  if (!cookieName) return null
  const cookie = document.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith(cookieName))
    ?.split('=')[1]
  return cookie
}

export const removeCookie = () => {
  document.cookie = `${VARIABLES.ACCESS_TOKEN}=;expires=${currentExpires};path=/`
  document.cookie = `${VARIABLES.REFRESH_TOKEN}=;expires=${currentExpires};path=/`
  document.cookie = `${VARIABLES.USERID}=;expires=${currentExpires};path=/`
  document.cookie = `${VARIABLES.EMAIL}=;expires=${currentExpires};path=/`
  document.cookie = `${VARIABLES.ACCOUNTID}=;expires=${currentExpires};path=/`
}

export const saveUserEmail = (email) => {
  document.cookie = `${VARIABLES.EMAIL}=${email};expires=${cookieExpires};path=/`
}

export const saveAccountId = (accountId) => {
  document.cookie = `${VARIABLES.ACCOUNTID}=${accountId};expires=${cookieExpires};path=/`
}
