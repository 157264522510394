import api from '.'

export const logout = (data) => {
  const url = '/logout'
  return api.post(url, data)
}

export const logIn = (data) => {
  const url = '/login'
  return api.post(url, data)
}

export const getAccessToken = (refreshToken) => {
  const url = '/refresh-token'
  return api.post(url, refreshToken)
}

export const getTrafficSummaryReport = (filters) => {
  const url = '/reports/traffic-summary'
  return api.post(url, filters)
}

export const getUserTimezoneDetails = () => {
  const url = '/timezone'
  return api.get(url)
}

export const updateUserTimezone = (id) => {
  const url = `/timezone/${id}`
  return api.put(url)
}

export const getReportCampaigns = (data) => {
  const url = '/reports/campaigns'
  return api.post(url, data)
}

export const exportTrafficSummary = (filters) => {
  const url = '/reports/export-traffic-summary'
  return api.post(url, filters, { responseType: 'blob', withCredentials: true })
}

export const getAdvancedReport = (filters) => {
  const url = '/reports/advanced-report'
  return api.post(url, filters)
}

export const exportAdvancedReport = (filters) => {
  const url = '/reports/export-advanced-report'
  return api.post(url, filters, { responseType: 'blob', withCredentials: true })
}

export const getUserDataByTempToken = (tempToken) => {
  const url = '/auth/temp-token'
  return api.get(url, { tempToken })
}
