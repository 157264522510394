export const allChannelColorArray = ['#007bff', '#f2bb14', '#17a2b8', '#DC3545'] // ['Total Sent', 'Delivered', 'Seen', 'Clicked']
export const smsChannelColorArray = ['#007bff', '#f2bb14'] // ['Total Sent', 'Delivered']
export const whatsappChannelColorArray = ['#007bff', '#f2bb14'] // ['Total Sent', 'Delivered']
export const emailChannelColorArray = ['#007bff', '#17a2b8', '#DC3545'] // ['Total Sent', 'Seen', 'Clicked']

export const colorCodes = {
  'total sent': '#007bff',
  sent: '#2F855A',
  processing: '#FAFF30',
  delivered: '#f2bb14',
  seen: '#17a2b8',
  replied: '#E004FF',
  failed: '#C53030',
  clicked: '#DC3545',
}
