import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Box, Button, CloseButton, Tooltip } from '@chakra-ui/react'
import Sidebar from './Sidebar'
import { AngleLeftIcon } from '../assetsComponents'
import { useResolutionService } from '../../hooks'

const position = 'right'

const SidebarWrapper = ({
  getButtonProps,
  getDisclosureProps,
  isOpen,
  onToggle,
}) => {
  const [hidden, setHidden] = useState(!isOpen)
  const [angleIconHover, setAngleIconHover] = useState(false)
  const { isLGScreen } = useResolutionService()
  const label = isOpen ? 'Close Sidebar' : 'Open Sidebar'
  return (
    <React.Fragment>
      <Box
        as='div'
        position={{
          base: 'absolute',
          sm: 'absolute',
          md: 'absolute',
          lg: 'relative',
        }}
        className='z1080'>
        <motion.div
          {...getDisclosureProps()}
          hidden={hidden}
          initial={false}
          onAnimationStart={() => setHidden(false)}
          onAnimationComplete={() => setHidden(!isOpen)}
          animate={{ width: isOpen ? 260 : 0 }}
          style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
          <Sidebar
            isActive={isLGScreen ? true : !angleIconHover}
            onToggle={onToggle}
          />
          <Box className='div' display={{ base: 'block', lg: 'none' }}>
            {isOpen && (
              <React.Fragment>
                <CloseButton
                  onClick={onToggle}
                  position='absolute'
                  top='5px'
                  left='265px'
                  border='1px solid #000'
                />
              </React.Fragment>
            )}
          </Box>
        </motion.div>
      </Box>
      <Box
        as='div'
        display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
        justifyContent='center'
        alignItems='center'>
        <Tooltip
          hasArrow
          label={label}
          bg='black'
          color='white'
          placement={position}
          p='2'
          borderRadius='5px'>
          <Button
            {...getButtonProps()}
            background='none'
            outline='none'
            _hover={{ background: 'none' }}
            _focusWithin={{ boxShadow: 'none' }}
            onMouseEnter={() => setAngleIconHover(true)}
            onMouseLeave={() => setAngleIconHover(false)}>
            <AngleLeftIcon isOpen={isOpen} />
          </Button>
        </Tooltip>
      </Box>
    </React.Fragment>
  )
}

export default SidebarWrapper
