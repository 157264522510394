import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, useToast } from '@chakra-ui/react'
import { useUserService } from '../hooks'
import { RCXSpinner } from '../components'
import {
  bugsnagClient,
  getHomepageUrl,
  saveAccountId,
  saveTokenCookie,
  saveUserEmail,
} from '../utils'
import { getUserDataByTempToken } from '../rest-client/service'
import { defaultErrorMessage } from '../utils/constants'

const GoogleSingInCallback = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tempTokenString = queryParams.get('tempToken')
  const toast = useToast()
  const { setUser } = useUserService()
  const navigate = useNavigate()

  useEffect(() => {
    const getUserDataByToken = async () => {
      try {
        const { data } = await getUserDataByTempToken(tempTokenString)
        const { accessToken, refreshToken, email, accountId } = data
        const homePageUrl = getHomepageUrl(accountId, true) // (accountId, getFullUrl)
        saveTokenCookie(accessToken, refreshToken)
        saveAccountId(accountId)
        saveUserEmail(email)
        setUser(data)
        bugsnagClient.setUser({ id: accountId })
        toast({
          description: 'You are successfully logged in.',
          status: 'success',
        })
        navigate(homePageUrl, { replace: true })
      } catch (error) {
        bugsnagClient.notify('Sign In Error')
        bugsnagClient.leaveBreadcrumb(
          'Error State',
          error?.response?.data || defaultErrorMessage,
          'error',
        )
        navigate('/google-login-error', { replace: true })
      }
    }
    getUserDataByToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempTokenString])

  return (
    <Box as='div'>
      <RCXSpinner h='100vh' />
    </Box>
  )
}

export default GoogleSingInCallback
