import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import { GoogleIcon } from '../assetsComponents'
import { VARIABLES } from '../../utils'

const GoogleSignInButton = () => {
  const handleGoogleSignIn = () => {
    window.location.href = `${VARIABLES.API_ENDPOINT}/auth/google` // Redirect to your server's Google Sign-In route
  }

  return (
    <Box as='div' mt='6'>
      <Button
        mb='6'
        leftIcon={<GoogleIcon width='22px' height='22px' />}
        colorScheme='teal'
        className='google-signin-btn s5'
        variant='outline'
        width='100%'
        onClick={handleGoogleSignIn}>
        Sign in with Google
      </Button>
      <hr className='login-section-separator'/>
    </Box>
  )
}

export default GoogleSignInButton
