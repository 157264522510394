import React from 'react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

const Checkbox = ({ label, colorScheme, isChecked = false, ...rest }) => {
  return (
    <ChakraCheckbox
      size='md'
      {...(colorScheme && colorScheme)}
      isChecked={isChecked}
      {...rest}>
      {label}
    </ChakraCheckbox>
  )
}

export default Checkbox
