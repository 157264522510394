import React from 'react'
import classNames from 'classnames'

const AngleLeftIcon = ({ isOpen = false, rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='16'
      width='10'
      viewBox='0 0 320 512'
      className={classNames({
        'rotate-0': isOpen,
        'rotate-180': !isOpen,
      })}
      cursor='pointer'
      {...rest}>
      <path d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z' />
    </svg>
  )
}

export default AngleLeftIcon
