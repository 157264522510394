import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Flex, useToast } from '@chakra-ui/react'
import {
  AdvanceReportFilters,
  AdvanceReportTable,
  ExportReport,
  PageHeader,
  ReportScreenMessages,
} from '../components'
import {
  allCampaignsOption,
  exportReportPageType,
  pageSizeArray,
} from '../constants'
import { TimezoneContext } from '../contexts'
import { VARIABLES, getCookieByName } from '../utils'
import { getAdvancedReport } from '../rest-client/service'
import { Pagination } from '../components/common'
import { defaultErrorMessage } from '../utils/constants'

export const defaultSelectedColumns = [
  { label: 'Sent Date', key: 'sentDate', value: true },
  { label: 'Sent At', key: 'sentTime', value: true },
  { label: 'Sender Id', key: 'senderId', value: true },
  { label: 'Destination Id', key: 'destinationNo', value: true },
  { label: 'Message', key: 'message', value: true },
  { label: 'First Name', key: 'firstName', value: true },
  { label: 'Phone Number', key: 'number', value: true },
  { label: 'Email', key: 'email', value: true },
  { label: 'Link Tracking', key: 'linkTracking', value: true },
]

export const ARFilterInitialValue = {
  sentFrom: '',
  sentTill: '',
  campaigns: allCampaignsOption.value,
  allCampaignsArray: [],
  channel: '',
  channelStatus: '',
  message: '',
  senderId: '',
  destinationNo: '',
  currentPage: 1,
  pageSize: pageSizeArray[0],
}

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Advance Report | ReplyCX'}</title>
      <meta name='title' content={'Advance Report | ReplyCX'}></meta>
      <meta property='og:title' content={'Advance Report | ReplyCX'}></meta>
      <meta
        property='twitter:title'
        content={'Advance Report | ReplyCX'}></meta>
    </Helmet>
  )
}

const AdvanceReport = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [reportData, setReportData] = useState([])
  const [filters, setFilters] = useState(ARFilterInitialValue)
  const [totalCount, setTotalCount] = useState(0)
  const [visibleColumnsArray, setVisibleColumnsArray] = useState(
    defaultSelectedColumns.map((column) => column.key),
  )
  const { timezone } = useContext(TimezoneContext)
  const accountId = getCookieByName(VARIABLES.ACCOUNTID)
  const toast = useToast()

  const getAdvancedReportData = useCallback(
    async (newFilters) => {
      const filtersParams = newFilters ? newFilters : filters
      const allCampaignsArray = newFilters
        ? newFilters?.allCampaignsArray
        : filters.allCampaignsArray
      const params = {
        filter: {
          accountId,
          timezone,
          ...(filtersParams?.campaigns === allCampaignsOption.value &&
            filtersParams?.sentFrom &&
            filtersParams?.sentTill && {
              campaigns: allCampaignsArray,
            }),
          ...(filtersParams?.campaigns !== allCampaignsOption.value && {
            campaigns: filtersParams?.campaigns,
          }),
          ...(filtersParams?.sentFrom && {
            sentFrom: filtersParams?.sentFrom,
          }),
          ...(filtersParams?.sentTill && {
            sentTill: filtersParams?.sentTill,
          }),
          ...(filtersParams?.channel && { channel: filtersParams?.channel }),
          ...(filtersParams?.channelStatus &&
            filtersParams?.channel && {
              edrStatus: filtersParams?.channelStatus,
            }),
        },
        ...((filtersParams?.message?.trim() ||
          filtersParams?.senderId?.trim() ||
          filtersParams?.destinationNo?.trim()) && {
          searchFilter: {
            ...(filtersParams?.message?.trim() && {
              message: filtersParams?.message?.trim(),
            }),
            ...(filtersParams?.senderId?.trim() && {
              senderId: filtersParams?.senderId?.trim(),
            }),
            ...(filtersParams?.destinationNo?.trim() && {
              destinationNo: filtersParams?.destinationNo?.trim(),
            }),
          },
        }),
        page: filtersParams?.currentPage,
        limit: filtersParams?.pageSize,
      }
      // 1) ((filtersParams?.sentFrom || filtersParams?.sentTill) && !(filtersParams?.sentFrom && filtersParams?.sentTill))
      // sentTill or sentFrom one of are selected but not both selected
      // 2) !filtersParams?.channel
      // If channel is not selected
      if (
        ((filtersParams?.sentFrom || filtersParams?.sentTill) &&
          !(filtersParams?.sentFrom && filtersParams?.sentTill)) ||
        !filtersParams?.channel
      ) {
        setIsFetching(false)
      } else {
        try {
          setIsFetching(true)
          const { data } = await getAdvancedReport(params)
          setIsFetching(false)
          setReportData(data?.result || [])
          setTotalCount(data?.count || 0)
        } catch (error) {
          // error
          setIsFetching(false)
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setReportData([])
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, timezone],
  )

  useEffect(() => {
    getAdvancedReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone])

  const renderPageHeader = () => {
    return (
      <PageHeader
        title='Advance Report'
        renderRightSide={() => {
          return isFetching ? null : (
            <ExportReport
              type={exportReportPageType.ADVANCE_REPORT}
              isDisabled={
                // 1) !(filters?.sentFrom || filters?.sentTill || filters?.channel || filters?.channelStatus || filters?.message || filters?.senderId || filters?.destinationNo || filters?.campaigns)
                // sentTill, sentFrom, channel, channelStatus, message, senderId, destinationNo, campaigns as least one selected, otherwise disabled export report
                // 2) !filtersParams?.channel
                // If channel is not selected, disabled export report
                // 3) reportData?.length === 0
                // If API response has no data
                // 4) visibleColumnsArray?.length === 0
                // If admin unchecks all the columns, the export button will be disabled
                !(
                  filters?.sentFrom ||
                  filters?.sentTill ||
                  filters?.channel ||
                  filters?.channelStatus ||
                  filters?.message ||
                  filters?.senderId ||
                  filters?.destinationNo ||
                  filters?.campaigns
                ) ||
                !filters?.channel ||
                reportData?.length === 0 ||
                visibleColumnsArray?.length === 0
              }
              filters={{
                filter: {
                  accountId,
                  timezone,
                  ...(filters?.campaigns === allCampaignsOption.value &&
                    filters?.sentFrom &&
                    filters?.sentTill && {
                      campaigns: filters?.allCampaignsArray,
                    }),
                  ...(filters?.campaigns !== allCampaignsOption.value && {
                    campaigns: filters?.campaigns,
                  }),
                  ...(filters?.sentFrom && {
                    sentFrom: filters?.sentFrom,
                  }),
                  ...(filters?.sentTill && {
                    sentTill: filters?.sentTill,
                  }),
                  ...(filters?.channel && {
                    channel: filters?.channel,
                  }),
                  ...(filters?.channelStatus &&
                    filters?.channel && {
                      edrStatus: filters?.channelStatus,
                    }),
                },
                ...((filters?.message?.trim() ||
                  filters?.senderId?.trim() ||
                  filters?.destinationNo?.trim()) && {
                  searchFilter: {
                    ...(filters?.message?.trim() && {
                      message: filters?.message?.trim(),
                    }),
                    ...(filters?.senderId?.trim() && {
                      senderId: filters?.senderId?.trim(),
                    }),
                    ...(filters?.destinationNo?.trim() && {
                      destinationNo: filters?.destinationNo?.trim(),
                    }),
                  },
                }),
              }}
              other={{
                visibleColumnsArray,
                totalCount,
              }}
            />
          )
        }}
      />
    )
  }

  return (
    <React.Fragment>
      {renderHelmet()}
      <Flex
        as='main'
        display='flex'
        flexDirection='column'
        height='100vh'
        className='advance-report'>
        {renderPageHeader()}
        <AdvanceReportFilters
          isFetching={isFetching}
          onApply={(newFiltersValue) => {
            setFilters((prev) => ({ ...prev, ...newFiltersValue }))
            getAdvancedReportData({ ...filters, ...newFiltersValue })
          }}
          columnSelectionChangeHandler={(newColumnArray) => {
            setVisibleColumnsArray(newColumnArray)
          }}
        />
        <React.Fragment>
          {reportData?.length > 0 &&
          filters?.channel &&
          visibleColumnsArray?.length > 0 ? (
            <Flex
              className='table-pagination-section'
              flexDirection='column'
              overflow={{ base: 'unset', md: 'unset', lg: 'hidden' }}
              h='100%'
              mt='2'>
              <Box as='div' overflow='overlay'>
                <AdvanceReportTable
                  data={reportData}
                  isFetching={isFetching}
                  currentPage={filters?.currentPage}
                  pageSize={filters?.pageSize}
                  channel={filters?.channel}
                  visibleColumnsArray={visibleColumnsArray}
                />
              </Box>
              <Box as='div'>
                {reportData?.length > 0 && !isFetching && (
                  <Pagination
                    totalCount={totalCount}
                    onApply={(newFiltersValue) => {
                      setFilters((prev) => ({ ...prev, ...newFiltersValue }))
                      getAdvancedReportData({ ...filters, ...newFiltersValue })
                    }}
                    isFetching={isFetching}
                    currentPage={filters?.currentPage}
                    pageSize={filters?.pageSize}
                  />
                )}
              </Box>
            </Flex>
          ) : (
            <ReportScreenMessages
              isFetching={isFetching}
              channel={filters?.channel}
              reportLength={reportData?.length}
              visibleColumnsLength={visibleColumnsArray?.length}
            />
          )}
        </React.Fragment>
      </Flex>
    </React.Fragment>
  )
}

export default AdvanceReport
