import React from 'react'
import classnames from 'classnames'
import { Flex, Spinner } from '@chakra-ui/react'

const RCXSpinner = ({ colorClass = 'text-saffron-700', ...rest }) => {
  return (
    <Flex justifyContent='center' alignItems='center' h='100%' {...rest}>
      <Spinner
        color='saffron.700'
        className={classnames(colorClass)}
        thickness='4px'
        speed='0.65s'
        size='lg'
      />
    </Flex>
  )
}

export default RCXSpinner
