import React, { useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { EyeIcon } from '../assetsComponents'

const InputField = React.forwardRef(
  (
    {
      id,
      label,
      labelProps,
      size,
      className,
      type,
      placeholder,
      onFocus,
      formGroupClassName,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false)

    const renderLabel = () => {
      if (label) {
        return (
          <FormLabel fontSize='' {...labelProps}>
            {label}
          </FormLabel>
        )
      }
      return null
    }

    const renderFormControl = () => {
      return (
        <Input
          ref={ref}
          className={className}
          size={size ? size : 'lg'}
          type={type === 'password' && showPassword ? 'text' : type}
          placeholder={placeholder}
          {...rest}
        />
      )
    }

    return (
      <Box
        controlid={id || `control-${rest?.name}-${Math.random()}`}
        className={formGroupClassName}
        _notFirst={{
          mt: '6',
        }}
        display='flex'
        flexDirection='column'
        position='relative'>
        <FormControl>
          {renderLabel()}
          <InputGroup>
            {renderFormControl()}
            {type === 'password' && (
              <InputRightElement
                pr='4'
                h='48px'
                pointerEvents={rest?.isDisabled ? 'none' : ''}>
                <EyeIcon
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  iconColor={rest?.iconColor || 'black'}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
      </Box>
    )
  },
)

export default InputField
