import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useUserService } from '../hooks'
import { homePageUrl } from '../utils/constants'

const PublicRoute = () => {
  const { user } = useUserService()
  if (user) {
    return <Navigate to={homePageUrl} />
  } else {
    return <Outlet />
  }
}

export default PublicRoute
