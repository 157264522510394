import axios from 'axios'
import { getAccessToken } from './service'
import {
  VARIABLES,
  getCookieByName,
  removeCookie,
  saveTokenCookie,
} from '../utils'

let retryCount = 0

const api = axios.create({
  baseURL: VARIABLES.API_ENDPOINT,
})

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = getCookieByName(VARIABLES.ACCESS_TOKEN)
    if (config?.tempToken) {
      config.headers.Authorization = config?.tempToken
    }
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject(error),
)

api.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response
  },
  async (error) => {
    // Check if the error is due to an expired token
    if (error.response && error.response.status === 401) {
      retryCount = retryCount + 1
      if (retryCount === 3) {
        removeCookie()
        // redirect to login page
        window.location.replace('/')
        return
      }
      try {
        // Attempt to refresh the access token and resend the original request
        const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
        const { data } = await getAccessToken({
          refreshToken,
        })

        const { accessToken, refreshToken: newRefreshToken } = data

        saveTokenCookie(accessToken, newRefreshToken)
        error.config.headers.Authorization = accessToken
        retryCount = 0
        return api(error.config)
      } catch (refreshError) {
        removeCookie()
        // redirect to login page
        window.location.replace('/')
      }
    } else if (
      error.response?.status === 403 &&
      error?.response?.data?.code === 'token_required'
    ) {
      removeCookie()
      // redirect to login page
      window.location.replace('/')
    }
    // Handle other errors
    return Promise.reject(error)
  },
)

export default api
