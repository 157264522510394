import React from 'react'
import { Box, Container, Stack, Text } from '@chakra-ui/react'
import { BackToHomeBtn } from '../components'

const GoogleSingInError = () => {
  return (
    <main>
      <Container
        as='section'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        w='100vw'
        h='100vh'>
        <Stack>
          <Text
            as='h2'
            fontSize={{
              base: '1.5rem',
              sm: '1.5rem',
              md: '2rem',
              lg: '2.5rem',
            }}
            fontWeight='600'
            textAlign='center'>
            Login Error
          </Text>
          <Text
            fontSize={{ base: '1rem', sm: '1rem', md: '1rem', lg: '1.25rem' }}
            fontWeight='400'
            textAlign='center'>
            You don't have access please contact support.
          </Text>
          <Box as='div' textAlign='center'>
            <BackToHomeBtn btnText='Back to Login' showLeftIcon={false} />
          </Box>
        </Stack>
      </Container>
    </main>
  )
}

export default GoogleSingInError
