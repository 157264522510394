import { VARIABLES } from './variables'
import { getCurrentExpiresDate } from './utility'
import { getCookieByName } from './cookie'

/**
 * Store and get user data
 *
 * @class Auth
 */

const currentExpires = getCurrentExpiresDate()

class AuthService {
  getUserData() {
    try {
      if (document.cookie) {
        const accessToken = getCookieByName(VARIABLES.ACCESS_TOKEN)
        const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
        const email = getCookieByName(VARIABLES.EMAIL)
        const accountId = getCookieByName(VARIABLES.ACCOUNTID)
        return { accessToken, refreshToken, email, accountId }
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  getLoginState() {
    const user = this.getUserData()
    return user ? true : false
  }

  getAccountId() {
    const user = this.getUserData()
    return user?.accountId
  }

  removeData() {
    document.cookie = `${VARIABLES.ACCESS_TOKEN}=;expires=${currentExpires};path=/`
    document.cookie = `${VARIABLES.REFRESH_TOKEN}=;expires=${currentExpires};path=/`
    document.cookie = `${VARIABLES.EMAIL}=;expires=${currentExpires};path=/`
    document.cookie = `${VARIABLES.ACCOUNTID}=;expires=${currentExpires};path=/`
  }

  isLogedIn() {
    const auth = this.getLoginState()
    if (auth) {
      return true
    } else {
      return false
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService()
