import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'

const ChakraModal = ({
  isOpen,
  onClose,
  onSuccess,
  title,
  isShowCloseIcon = true,
  confirmationText,
  confirmationTextFn = () => {},
  isShowPrimaryButton = true,
  isShowSecondaryButton = true,
  primaryBtnColorScheme = 'gray',
  primaryBtnvariant = 'outline',
  primaryBtnText = 'Cancel',
  secondaryBtnColorScheme = 'twitter',
  secondaryBtnvariant = 'solid',
  secondaryBtnText = 'OK',
  isLoading = false,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}
        {isShowCloseIcon && !isLoading && <ModalCloseButton />}
        <ModalBody>
          {confirmationText ? (
            <Box as='span' className='s7' fontWeight='400'>
              {confirmationText}
            </Box>
          ) : (
            confirmationTextFn()
          )}
        </ModalBody>
        <ModalFooter>
          {isShowPrimaryButton && (
            <Button
              colorScheme={primaryBtnColorScheme}
              variant={primaryBtnvariant}
              mr={isShowSecondaryButton ? 3 : 0}
              isDisabled={isLoading}
              onClick={onClose}>
              {primaryBtnText}
            </Button>
          )}
          {isShowSecondaryButton && (
            <Button
              colorScheme={secondaryBtnColorScheme}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={onSuccess}
              variant={secondaryBtnvariant}>
              {secondaryBtnText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ChakraModal
