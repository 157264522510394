import React from 'react'
import classNames from 'classnames'
import {
  Box,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { channelMappingObject } from '../../constants'
import { defaultSelectedColumns } from '../../pages'

const AdvanceReportTable = ({
  data,
  isFetching,
  currentPage,
  pageSize,
  channel,
  visibleColumnsArray,
}) => {
  const renderMessage = (message) => {
    return (
      <React.Fragment>
        {message ? (
          <Box
            textAlign='left'
            as='pre'
            width='400px'
            className='s7'
            fontWeight='400'
            whiteSpace='pre-line'>
            {message}
          </Box>
        ) : (
          'N/A'
        )}
      </React.Fragment>
    )
  }

  return (
    <Box as='section' my='4'>
      <TableContainer className='custom-table table-header-fixed'>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>#</Th>
              {defaultSelectedColumns.map((column) => (
                <Th
                  key={column.key}
                  className={classNames({
                    'd-none': !visibleColumnsArray.includes(column.key),
                  })}>
                  {column.label}
                </Th>
              ))}
              {/* <Th>Link Clicked</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {isFetching ? (
              <Tr>
                <Td colSpan='10' textAlign='center'>
                  <Spinner size='sm' />
                </Td>
              </Tr>
            ) : (
              <React.Fragment>
                {data?.map((report, index) => (
                  <Tr key={index}>
                    <Td>{index + 1 + pageSize * (currentPage - 1)}</Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('sentDate'),
                      })}>
                      {report?.sentDate ? report?.sentDate : 'N/A'}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('sentTime'),
                      })}>
                      {report?.sentTime ? report?.sentTime : 'N/A'}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('senderId'),
                      })}>
                      {report?.senderId}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none':
                          !visibleColumnsArray.includes('destinationNo'),
                      })}>
                      {report?.destinationNo}
                    </Td>
                    <Td
                      textAlign='center'
                      whiteSpace='normal'
                      wordBreak='break-word'
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('message'),
                      })}>
                      {renderMessage(report?.message)}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('firstName'),
                      })}>
                      {report?.firstName}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('number'),
                      })}>
                      {report?.number ? report?.number : 'N/A'}
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('email'),
                      })}>
                      <Box
                        as='div'
                        width={
                          channel === channelMappingObject.email ? '250px' : ''
                        }
                        whiteSpace='normal'
                        wordBreak='break-word'>
                        {report?.email ? report?.email : 'N/A'}
                      </Box>
                    </Td>
                    <Td
                      className={classNames({
                        'd-none': !visibleColumnsArray.includes('linkTracking'),
                      })}>
                      {report?.linkTracking ? 'Enabled' : 'Disabled'}
                    </Td>
                    {/* <Td>{true ? 'Yes' : 'No'}</Td> */}
                  </Tr>
                ))}
              </React.Fragment>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AdvanceReportTable
