import React, { useContext, useEffect, useState } from 'react'
import { Box, FormLabel, Spinner, useToast } from '@chakra-ui/react'
import { SelectionField } from '../form'
import {
  getUserTimezoneDetails,
  updateUserTimezone,
} from '../../rest-client/service'
import { TimezoneContext } from '../../contexts'
import { defaultErrorMessage } from '../../utils/constants'

const TimezoneSelection = () => {
  const [timezoneOptions, setTimezoneOptions] = useState([])
  const [isUpdatingTimezone, setIsUpdatingTimezone] = useState(false)
  const { isFetchingTimezone, setIsFetchingTimezone, setTimezone, timezone } =
    useContext(TimezoneContext)
  const toast = useToast()

  useEffect(() => {
    const getUserTimezone = async () => {
      getUserTimezoneDetails()
        .then((data) => {
          const { data: timezone } = data

          const options = timezone.reduce((prevOption, currentOption) => {
            if (currentOption.isDefault) {
              setTimezone(currentOption?.value)
            }
            return [
              ...prevOption,
              {
                uniqueId: currentOption?.uniqueId,
                value: currentOption?.value,
                label: currentOption?.name,
              },
            ]
          }, [])
          setTimezoneOptions(options)
          setIsFetchingTimezone(false)
        })
        .catch((error) => {
          // error
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setIsFetchingTimezone(false)
        })
    }
    getUserTimezone()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const timezoneChangeHandler = async (newTimezoneValue) => {
    const id = timezoneOptions.find(
      (timezoneOption) => timezoneOption.value === newTimezoneValue,
    )?.uniqueId
    updateUserTimezone(id)
      .then((data) => {
        setTimezone(newTimezoneValue)
        setIsUpdatingTimezone(false)
      })
      .catch((error) => {
        // error
        const { response } = error
        toast({
          description: response?.data?.message || defaultErrorMessage,
          status: 'error',
        })
        setIsUpdatingTimezone(false)
      })
  }

  return (
    <Box as='div' maxW='228px' mx='4' my='2' className='timezone-section'>
      <FormLabel className='s8' letterSpacing='0.05rem'>
        Timezone:{' '}
        {(isUpdatingTimezone || isFetchingTimezone) && (
          <Spinner mr='3' size='sm' />
        )}
      </FormLabel>
      <SelectionField
        onChange={(e) => {
          setTimezone(e.target.value)
          setIsUpdatingTimezone(true)
          timezoneChangeHandler(e.target.value)
        }}
        value={timezone}
        className='s6'
        options={timezoneOptions}
        placeholder={isFetchingTimezone ? 'Select timezone' : ''}
        colorScheme='gray'
        isDisabled={isFetchingTimezone || isUpdatingTimezone}
        getValueAsOption
      />
    </Box>
  )
}

export default TimezoneSelection
