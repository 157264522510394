import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import packageJson from '../../package.json'
import { getCookieByName } from './cookie'
import { VARIABLES } from './variables'
global.appVersion = packageJson.version

const accountId = getCookieByName(VARIABLES.ACCOUNTID)

const bugsnagClient = Bugsnag.start({
  apiKey: VARIABLES.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request'],
  releaseStage: VARIABLES.BUGSNAG_RELEASE_STAGES,
  enabledReleaseStages: ['development', 'staging', 'production'],
  appVersion: global?.appVersion,
  appType: 'client',
  onError: function (event) {
    event.setUser(accountId)
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default ErrorBoundary
export { bugsnagClient }
