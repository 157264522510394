import React from 'react'
import { Box } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useResolutionService } from '../../hooks'

const NavItems = ({ links, onToggle }) => {
  const { isLGScreen } = useResolutionService()
  return (
    <Box as='aside' id='sidebar' className='position-relative' pl='4'>
      <Box as='nav' className='nav'>
        {links.map((link, index) => (
          <NavLink
            className='s6 navlink'
            to={link.path}
            key={index}
            onClick={() => {
              if (!isLGScreen) {
                onToggle()
              }
            }}>
            {link.name}
          </NavLink>
        ))}
      </Box>
    </Box>
  )
}

export default NavItems
