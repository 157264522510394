import React from 'react'
import { Badge, Box, Text } from '@chakra-ui/react'

const PageHeader = ({ title, renderRightSide = () => null }) => {
  return (
    <Box as='div' mb='3'>
      <Text className='s6' mt='4' display={{ base: 'none', lg: 'block' }}>
        <Badge
          borderRadius='full'
          px='4'
          py='2'
          cursor='pointer'
          textTransform='unset'>
          ReplyCX
        </Badge>
      </Text>
      <Box
        as='div'
        display='flex'
        flexDirection={{
          base: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
        }}
        justifyContent='space-between'
        mt='4'>
        <Text className='s3' fontSize='2rem'>
          {title}
        </Text>
        {renderRightSide()}
      </Box>
    </Box>
  )
}

export default PageHeader
