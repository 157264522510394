import React, { useState } from 'react'
import { SelectionField } from '../form'
import { pageSizeArray } from '../../constants'

const TableEntriesSelection = ({ isDisabled, onApply, pageSize: limit }) => {
  const [pageSize, setPageSize] = useState(limit || pageSizeArray[0]) // pageSizeArray[0] = 25
  return (
    <SelectionField
      onChange={(e) => {
        setPageSize(e.target.value)
        onApply({
          currentPage: 1,
          pageSize: e.target.value,
        })
      }}
      value={pageSize}
      options={pageSizeArray}
      isDisabled={isDisabled}
      maxWidth='80px'
      my='2'
    />
  )
}

export default TableEntriesSelection
