import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import App from './App'
import './styles/index.scss'

const fonts = {
  body: 'Poppins',
  heading: 'Poppins',
}

const theme = extendTheme({ fonts })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <ChakraProvider
    theme={theme}
    toastOptions={{
      defaultOptions: {
        position: 'top-right',
        duration: '3000',
        isClosable: true,
      },
    }}>
    <App />
  </ChakraProvider>,
  //</React.StrictMode>
)
