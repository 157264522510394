import React, { useEffect, useState } from 'react'
import { Stack, Radio, RadioGroup, Text, Link } from '@chakra-ui/react'
import { ChakraModal } from '../common'
import { numberWithCommas } from '../../utils'

const exportReportColumnSelection = {
  ALL_COLUMNS: '1',
  SELECTED_COLUMNS: '2',
}

const ExportRecordsModal = ({ onClick, other, isLoading, isOpen, onClose }) => {
  const [columnsSelection, setColumnsSelection] = useState(
    other?.visibleColumnsArray?.length < 9
      ? exportReportColumnSelection.SELECTED_COLUMNS
      : exportReportColumnSelection.ALL_COLUMNS,
  )

  useEffect(() => {
    setColumnsSelection(
      other?.visibleColumnsArray?.length < 9
        ? exportReportColumnSelection.SELECTED_COLUMNS
        : exportReportColumnSelection.ALL_COLUMNS,
    )
  }, [isOpen, other])

  return (
    <React.Fragment>
      {other?.totalCount > 10000 ? (
        <ChakraModal
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isLoading}
          confirmationTextFn={() => {
            return (
              <Text as='span' className='s7' fontWeight='400'>
                The export list has 10,000+ records, please contact{' '}
                <Link
                  href='mailto:support@techalphagroup.com'
                  color='teal.500'
                  //isExternal
                >
                  support@techalphagroup.com
                </Link>{' '}
                to get the complete list.
              </Text>
            )
          }}
          primaryBtnText='Close'
          isShowSecondaryButton={false}
          title={`Exporting ${numberWithCommas(other?.totalCount)} records`}
        />
      ) : (
        <ChakraModal
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isLoading}
          confirmationTextFn={() => {
            return (
              <RadioGroup
                onChange={setColumnsSelection}
                value={columnsSelection}>
                <Stack>
                  <Radio
                    value={exportReportColumnSelection.SELECTED_COLUMNS}
                    // other?.visibleColumnsArray?.length === 9
                    // total number of columns in the table is "9" if admin not unchecked any visible columns, disabled this radio
                    isDisabled={other?.visibleColumnsArray?.length === 9}>
                    Export report with currently displayed columns
                  </Radio>
                  <Radio value={exportReportColumnSelection.ALL_COLUMNS}>
                    Export report with all columns
                  </Radio>
                </Stack>
              </RadioGroup>
            )
          }}
          onSuccess={() =>
            onClick(
              columnsSelection === exportReportColumnSelection.ALL_COLUMNS,
            )
          }
          secondaryBtnText='Export'
          title={`Exporting ${numberWithCommas(other?.totalCount)} records`}
        />
      )}
    </React.Fragment>
  )
}

export default ExportRecordsModal
