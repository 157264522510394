import React, { useState } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import ExportRecordsModal from './ExportRecordsModal'
import { ExportReportBtn } from '../reports'
import { SelectionField } from '../form'
import { exportReportPageType, reportFormatOptions } from '../../constants'
import {
  exportAdvancedReport,
  exportTrafficSummary,
} from '../../rest-client/service'

const errorMessage =
  'Something went wrong in downloading the report, please try again'

const ExportReport = ({ isDisabled, filters, type, other }) => {
  const options = reportFormatOptions
  const [isDownloading, setIsDownloading] = useState(false)
  const [reportFormat, setReportFormat] = useState(reportFormatOptions[0].value)
  const [showModal, setShowModal] = useState(false)
  const toast = useToast()
  const reportFilename =
    type === exportReportPageType.ADVANCE_REPORT
      ? 'advance_report'
      : 'traffic_summay_report'

  const getApiFunction = (filters) => {
    if (type === exportReportPageType.ADVANCE_REPORT) {
      return exportAdvancedReport({ ...filters, exportType: reportFormat })
    } else {
      return exportTrafficSummary({ ...filters, exportType: reportFormat })
    }
  }

  const downloadReport = async (filterValue) => {
    let xlsxFile, downloadLink
    const exportType = reportFormat
    let filename
    if (exportType === 'xlsx') {
      filename = `${reportFilename}.xlsx`
    } else {
      filename = `${reportFilename}.csv`
    }
    setIsDownloading(true)
    try {
      const { data } = await getApiFunction(filterValue)
      if (data) {
        if (exportType === 'xlsx') {
          xlsxFile = new Blob([data])
        } else {
          xlsxFile = new Blob([data], { type: 'text/csv' })
        }

        // Download link
        downloadLink = document.createElement('a')

        // File name
        downloadLink.download = filename

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(xlsxFile)

        // Hide download link
        downloadLink.style.display = 'none'

        // Add the link to DOM
        document.body.appendChild(downloadLink)

        // Click download link
        downloadLink.click()

        setIsDownloading(false)
        setShowModal(false)
      }
    } catch (error) {
      const { response } = error
      toast({
        description: response?.data?.message || errorMessage,
        status: 'error',
      })
      setIsDownloading(false)
      setShowModal(false)
    }
  }

  return (
    <Box as='div' display='flex' mt={{ base: '3', sm: '3', md: '3', lg: '0' }}>
      {type === exportReportPageType.ADVANCE_REPORT &&
        other?.visibleColumnsArray && (
          <ExportRecordsModal
            other={other}
            isLoading={isDownloading}
            isOpen={showModal}
            onClose={() => {
              if (isDownloading) return
              setShowModal(false)
            }}
            onClick={(isExportAllColumns) => {
              downloadReport(
                isExportAllColumns
                  ? filters
                  : { ...filters, columns: other?.visibleColumnsArray },
              )
            }}
          />
        )}
      <SelectionField
        onChange={(e) => setReportFormat(e.target.value)}
        value={reportFormat}
        options={options}
        defaultValue={reportFormatOptions[0].value}
        isDisabled={isDownloading || isDisabled}
        maxW='250px'
        getValueAsOption
      />
      <ExportReportBtn
        ml='2'
        mr={{ base: '0', sm: '0', md: '0', lg: '2' }}
        variant='outline'
        colorScheme='red'
        w='250px'
        isDisabled={isDownloading || !reportFormat || isDisabled}
        isLoading={isDownloading}
        onClick={() => {
          if (type === exportReportPageType.ADVANCE_REPORT) {
            setShowModal(true)
          } else {
            downloadReport(filters)
          }
        }}
      />
    </Box>
  )
}

export default ExportReport
