import React, { useState } from 'react'
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import EmailField from './EmailField'
import GoogleSignInButton from './GoogleSignInButton'
import { InputField } from '../form'
import { useInput, useUserService } from '../../hooks'
import { emailRegExr } from '../../constants'
import {
  bugsnagClient,
  getHomepageUrl,
  saveTokenCookie,
  saveUserEmail,
  saveAccountId,
} from '../../utils'
import { logIn } from '../../rest-client/service'
import { defaultErrorMessage } from '../../utils/constants'

const isValidEmail = (value) => value.match(emailRegExr)
const isValidPassword = (value) => value.trim() !== ''

const LoginForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const toast = useToast()
  const { setUser } = useUserService()
  const navigate = useNavigate()

  const {
    value: email,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    setIsTouched: setIsTouchedEmail,
  } = useInput(isValidEmail, '') // function, defaultValue

  const {
    value: password,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    setIsTouched: setIsTouchedPassword,
  } = useInput(isValidPassword, '') // function, defaultValue

  const onSubmit = async (e) => {
    const isValid = email.match(emailRegExr) || password.trim() !== ''
    if (emailHasError || passwordHasError || !isValid) return
    setIsSubmitting(true)
    logIn({ email, password })
      .then((data) => {
        const { accessToken, refreshToken, email, accountId } = data?.data
        const homePageUrl = getHomepageUrl(accountId)
        saveTokenCookie(accessToken, refreshToken)
        setUser(data?.data)
        saveAccountId(accountId)
        saveUserEmail(email)
        bugsnagClient.setUser({ id: accountId })
        setIsSubmitting(false)
        navigate(homePageUrl, { replace: true })
        toast({
          description: 'You are successfully logged in.',
          status: 'success',
        })
      })
      .catch((error) => {
        if (error?.code === 'ERR_NETWORK') {
          setError({ message: error?.message || defaultErrorMessage })
        } else {
          setError(error?.response?.data || { message: defaultErrorMessage })
        }
        bugsnagClient.notify('Sign In Error')
        bugsnagClient.leaveBreadcrumb(
          'Error State',
          error?.response?.data || defaultErrorMessage,
          'error',
        )
        setIsSubmitting(false)
      })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        setIsTouchedEmail(true)
        setIsTouchedPassword(true)
        onSubmit(e)
      }}>
      <Box as='div'>
        {error ? (
          <div className='login-error-wrapper mb-2'>
            <Alert status='error' borderRadius='5' justifyContent='center'>
              <AlertDescription fontSize='0.875rem'>
                {error?.message}
              </AlertDescription>
            </Alert>
          </div>
        ) : null}
        <GoogleSignInButton />
        <EmailField
          value={email}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          isInvalid={emailHasError}
          isDisabled={isSubmitting}
          className='inputfield'
        />
        <InputField
          name='password'
          type='password'
          label='Password'
          value={password}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          isInvalid={passwordHasError}
          isDisabled={isSubmitting}
          className='inputfield'
        />
        <Button
          type='submit'
          my='1.5rem'
          w='100%'
          colorScheme='blue'
          size='lg'
          isDisabled={isSubmitting}
          isLoading={isSubmitting}>
          Sign In
        </Button>
      </Box>
    </form>
  )
}

export default LoginForm
