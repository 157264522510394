import React from 'react'

const EyeIcon = ({ showPassword, setShowPassword, iconColor }) => {
  return (
    <>
      {showPassword ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 16.36 13.8'
          width='16.5'
          height='16.5'
          onClick={() => setShowPassword(!showPassword)}
          cursor='pointer'>
          <g id='ob-visibility_off_svg__b'>
            <g id='ob-visibility_off_svg__c'>
              <path
                d='M2.83 3.03.73.94 1.67 0l12.88 12.87-.93.93s-.09-.08-.14-.13l-1.94-1.94c-.07-.08-.13-.1-.24-.06-2.91 1.14-5.61.73-8.12-1.08C1.82 9.6.8 8.3.04 6.8a.28.28 0 0 1 0-.28c.67-1.3 1.56-2.43 2.67-3.39.04-.03.07-.06.12-.11Zm2.01 1.99c-.79 1.47-.38 3.4.93 4.52 1.3 1.11 3.07 1.07 4.07.47-.34-.34-.68-.67-1.01-1.01-.1-.1-.19-.12-.32-.11-.27.01-.54.03-.8-.03-1.17-.25-1.9-1.34-1.74-2.53 0-.07 0-.17-.03-.21-.36-.37-.72-.73-1.1-1.11ZM13.72 10.16c-.3-.3-.6-.6-.89-.9l-1.07-1.07c-.08-.08-.09-.14-.05-.24a3.744 3.744 0 0 0-4.79-4.81c-.06.02-.17.02-.21-.02-.5-.48-.98-.97-1.47-1.46a.443.443 0 0 1-.05-.09c.52-.14 1.02-.32 1.53-.41 2.66-.49 4.96.29 6.98 2 1.1.93 1.97 2.06 2.63 3.35.03.07.05.17.02.24a10.69 10.69 0 0 1-2.55 3.34c-.02.02-.05.04-.08.06Z'
                fill='#333333'
              />
              <path
                d='M10.43 6.81 8.06 4.44c.63-.03 1.19.16 1.66.6.51.48.74 1.07.71 1.76Z'
                fill='#333333'
              />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 16.5 11.25'
          width='16.5'
          height='16.5'
          onClick={() => setShowPassword(!showPassword)}
          cursor='pointer'>
          <path
            d='M8.25 0A8.86 8.86 0 0 0 0 5.63c1.79 4.56 6.94 6.8 11.49 5.01 2.29-.9 4.11-2.72 5.01-5.01A8.85 8.85 0 0 0 8.25 0Zm0 9.38C6.18 9.38 4.5 7.7 4.5 5.63c0-2.07 1.68-3.75 3.75-3.75 2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75Zm0-6C7.01 3.38 6 4.39 6 5.63s1.01 2.25 2.25 2.25 2.25-1.01 2.25-2.25-1-2.25-2.24-2.25Z'
            fill='#333333'
          />
        </svg>
      )}
    </>
  )
}

export default EyeIcon
