import React from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '../assetsComponents'

const MenuSearchInput = ({ search, setSearch, ...rest }) => {
  return (
    <InputGroup>
      <InputLeftElement alignSelf='center' height='40px' top='8px'>
        <SearchIcon />
      </InputLeftElement>
      <Input
        type='search'
        my='2'
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        {...rest}
      />
    </InputGroup>
  )
}

export default MenuSearchInput
