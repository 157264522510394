import React from 'react'

const SignoutIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...rest}>
      <path
        d='M11 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H11'
        stroke='#FFFFFF'
        strokeWidth='2px'
        strokeLinecap='round'
        fill='none'
      />
      <path
        d='M20 12H11M20 12L16 16M20 12L16 8'
        stroke='#FFFFFF'
        strokeWidth='2px'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </svg>
  )
}

export default SignoutIcon
