import React from 'react'
import { Box, FormHelperText as ChakraFormHelperText } from '@chakra-ui/react'

const FormHelperText = ({ text, isRequired = false, requiredText }) => {
  return (
    <ChakraFormHelperText>
      <Box as='span'>{text}</Box>
      <Box as='span' color='red'>
        {isRequired ? ` ${requiredText}` : ''}
      </Box>
    </ChakraFormHelperText>
  )
}

export default FormHelperText
